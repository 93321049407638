@keyframes line-in {
    0% {
        opacity: 0;
        transform: translateX(100px) scaleX(8);
    }

    1% {
        opacity: 1;
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes line-out {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 0;
        transform: translateX(100px) scaleX(4);
    }
    to {
        opacity: 0;
        transform: none;
    }
}

.nav {
    width: 100%;
    height: 100%;
    padding: var(--gap) calc(3 * var(--gap));
    display: flex;
    align-items: flex-start;

    @media (min-width: 900px) {
        align-items: center;
    }
}

.list {
    margin: 20vh auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 900px) {
        margin: 0;
        align-items: flex-start;
    }
}

.item {
    padding: 3vh 0;
}

.link {
    position: relative;
    color: var(--navigation-color-active);
    font-size: 1.6em;
    text-decoration: none;
    text-transform: uppercase;
    transition: color var(--transition-duration) var(--ease-in-out-expo);
    border: none;

    &:not([disabled]):hover {
        color: var(--navigation-color);
        transition: color var(--transition-duration) var(--ease-in-out-expo);

        &::after {

            @media (prefers-reduced-motion: reduce) {
                opacity: 0;
            }

            @media (prefers-reduced-motion: no-preference) {
                animation: line-in 0.25s var(--ease-out-quad) both;
            }
        }
    }

    &:not([disabled]):focus {
        outline: 2px dashed var(--navigation-color);
        outline-offset: 10px;
    }

    &:not(.active)::after {

        @media (any-hover: none) {
            display: none;
        }
    }

    &::after {
        width: 80px;
        height: 2px;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        content: '';
        opacity: 0;
        background: linear-gradient(135deg, var(--navigation-color), var(--navigation-color-active));
        transform-origin: left center;
        animation: line-out 0.25s var(--ease-out-quad) both;
    }

    @media (min-width: 600px) {
        font-size: 1.8em;
    }

    @media (min-width: 900px) {
        font-size: 2em;
    }

    @media (min-width: 1200px) {
        font-size: 2.2em;
    }

    @media (min-width: 1500px) {
        font-size: 2.4em;
    }
}

.active {
    color: hsl(var(--color-blue-lighten-2));
    transition: color var(--transition-duration) var(--ease-in-out-expo);
    pointer-events: none;

    &::after {
        width: 100%;
        top: 55%;
        opacity: 1;
        background: linear-gradient(135deg, hsl(var(--color-white)), hsl(var(--color-blue-lighten-5)));
        transform: translateY(-50%);
        animation: none;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    }
}
